import axios from "axios"

export default {
    api: {
        async findOrCreate(selectedDate) {
            return axios.post('parte-diario/find-or-create', {date: selectedDate})
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async getListadoPartes(fecha, area){
            return axios.get('parte-diario-empleado', {params:{fecha: fecha, area: area}})
            .then((response) => { return response.data })
            .catch((error) => { throw error })
        },
        async getGuardiasByDay(fecha){
            return axios.get('guardias', {params:{fecha: fecha}})
            .then((response) => { return response.data })
            .catch((error) => { throw error })
        },
        async findForUser(selectedDate) {
            return axios.get('parte-diario/for-user', {params: {date: selectedDate}})
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async addEmpleadoToParte(parte) {
            return axios.post('parte-diario-empleado', parte)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async editEmpleadoToParte(parte) {
            return axios.put('parte-diario-empleado/'+parte.id, parte)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async deleteEmpleadoToParte(parteId) {
            return axios.delete('parte-diario-empleado/'+parteId)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        }
    }
}