<template>
    <div class="card" v-if="partes">
        <h3 class="card-title">Parte diario de {{today.toFormat('dd/LL/yyyy')}}</h3>
        <div class="d-flex space-between w-100">
            <router-link :to="previous()" class="btn btn-outline-dark" style="max-width:auto!important; width: fit-content;">
                <icon feather="arrow-left" />
            </router-link>
            <router-link :to="next()" class="btn btn-outline-dark" >
                <icon feather="arrow-right" />
            </router-link>
        </div>
        <table-list
        :actionButton="actionButton"
        :showFilter="true"
        :data="partesForTableList"
        :header="headerPartes"
        :body="bodyPartes"
        @rowClicked="parteClicked"
      ></table-list>
        <Modal v-if="showAdd" @close="showAdd = false">
            <CargarEmpleadoAParteDiario
            slot="modal-body"
            :personas="personasSinCargar"
            :tiposLicencia="tiposLicencia"
            :fecha="today.toFormat('yyyy-LL-dd')"
            @saved="empleadoSaved"
            />
        </Modal>
        <Modal v-if="showEdit" @close="closeEdit">
            <EditarEmpleadoDeParteDiario
            slot="modal-body"
            :parte-selected="parteDiarioSelected"
            :tiposLicencia="tiposLicencia"
            @saved="empleadoEdited"
            />
        </Modal>
    </div>
</template>
<script>
import { DateTime } from 'luxon';
import { PersonasService } from '../../personas/services/PersonasServices';
import { TiposLicenciasServices } from '../../tipos_licencias/services/TiposLicenciasServices';
import ParteDiarioServices from '../services/ParteDiarioServices';
import Modal from '../../../components/elements/Modal.vue';
import CargarEmpleadoAParteDiario from './CargarEmpleadoAParteDiario.vue';
import EditarEmpleadoDeParteDiario from './EditarEmpleadoDeParteDiario.vue';
import Swal from 'sweetalert2';
import TableList from '../../../components/elements/TableList.vue';

export default {
    name: "CreateAndEditParteDiario",
    data() {
        return {
            today: null,
            partes: null,
            parteDiarioSelected: null,
            personas: null,
            tiposLicencia: null,
            showAdd: false,
            showEdit: false,
        };
    },
    components: { Modal, CargarEmpleadoAParteDiario, EditarEmpleadoDeParteDiario, TableList },
    computed:{
        personasSinCargar(){
            return this.personas.filter((persona) => !this.partes.some((parte) => parte.empleado_id === persona.id))
        },
        actionButton(){
            return {
                text: "Cargar parte",
                action: () => {
                    this.showAdd = true
                },
            }
        },
        headerPartes(){
            return [
            { value: 'Empleado', sortable: true },
            { value: 'Estado', sortable: true },
            { value: 'Nota', sortable: true },
            { value: 'Info', sortable: true },
            ]
        },
        bodyPartes(){
            return [
                {
                    class: 'w-100',
                    data: [{ value: 'empleado', class: 'important', label: 'Empleado' }]
                },
                {
                    class: 'w-100',
                    data: [{ value: 'estado', label: "Estado", mutator: (item) => {
                        if(item === 'Presente'){
                            return `<span class="text-bold text-success">${item}</span>`
                        }else{
                            return `<span class="text-bold text-danger">${item}</span>`
                        }
                    } }]
                },
                {
                    class: 'w-100',
                    data: [{ value: 'notas', label: "Nota" }]
                },
                {
                    class: 'w-100',
                    data: [
                        { value: 'info.descripcion', label: "", class:"text-bold" },
                        { value: 'info.desde', class:"text-secondary", label: "" },
                        { value: 'info.hasta', class:"text-secondary", label: "" },
                    ]
                }
            ]
        },
        partesForTableList(){
            const items = []
            this.partes.forEach((parte) => {
                const item = {
                    empleado: parte.empleado.nombre,
                    estado: parte.estado,
                    notas: parte.notas,
                    parte: parte
                }
                if(parte.licencia_id){
                    item.info = {
                        descripcion: parte.licencia.descripcion,
                        desde: 'Desde: '+this.formatDate(parte.licencia_desde),
                        hasta: 'Hasta: '+this.formatDate(parte.licencia_hasta),
                    }
                }
                items.push(item)
            })
            return items
        },
    },
    async mounted() {
        this.today = DateTime.fromFormat(this.$route.params.date, "yyyyLLdd");
        const response = await ParteDiarioServices.api.findForUser(this.today.toFormat("yyyy-LL-dd"));
        this.partes = response
        PersonasService.api.getForParteDiario().then((personas) => this.personas = personas);
        TiposLicenciasServices.api.getAll().then((tiposLicencia) => this.tiposLicencia = tiposLicencia);
    },
    methods: {
        parteClicked(parte){
            Swal.fire({
            title: '¿Que acción desea realizar?',
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Editar',
            denyButtonText: `Eliminar`,
            cancelButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.parteDiarioSelected = parte.parte
                    this.showEdit = true
                } else if (result.isDenied) {
                    this.deleteParte(parte.parte)
                }
            })
        },
        async empleadoSaved(parte){
            this.partes.push(parte)
            this.showAdd = false
            const response = await ParteDiarioServices.api.findForUser(this.today.toFormat("yyyy-LL-dd"));
            this.partes = response

        },
        deleteParte(parte){
            Swal.fire({
            title: '¿Seguro desea eliminar??',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, seguro',
            cancelButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    ParteDiarioServices.api.deleteEmpleadoToParte(parte.id).then(() => {
                        this.partes = this.partes.filter((p) => p.id !== parte.id)
                    })
                }
            })
        },
        async empleadoEdited(editedParte){
            this.partes.forEach(parte => {
                if(parte.id === editedParte.id){
                    parte = editedParte
                }
            });
            this.parteDiarioSelected = null
            this.showEdit = false
            const response = await ParteDiarioServices.api.findForUser(this.today.toFormat("yyyy-LL-dd"));
            this.partes = response

        },
        formatDate(date){
            if(date){
                return DateTime.fromFormat(date, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')
            }
        },
        next(){
            const nextDay = this.today.plus({day: 1})
            console.log(nextDay)
            return {
                name: 'CreateAndEditParteDiario',
                params: {
                    date: nextDay.toFormat('yyyyLLdd')
                }
            }
        },
        previous(){
            const prevDay = this.today.minus({day: 1})
            return {
                name: 'CreateAndEditParteDiario',
                params: {
                    date: prevDay.toFormat('yyyyLLdd')
                }
            }
        },
        closeEdit(){
          this.showEdit = false
          this.parteDiarioSelected = null
        }
    },
    async beforeRouteUpdate(to, from, next) {
        this.today = DateTime.fromFormat(to.params.date, "yyyyLLdd");
        const response = await ParteDiarioServices.api.findForUser(this.today.toFormat("yyyy-LL-dd"));
        this.partes = response
        next()
    }
}
</script>
<style scoped>
.btn {
    border: none;
    padding: .3rem .75rem;
    min-width: 0;
}
.btn-outline-dark {
    border: 1px solid var(--neutral-100);
    background-color: white;
    color: var(--neutral-100);
}

.btn-outline-dark:hover {
    background-color: white;
    color: var(--neutral-100);
}

</style>
