<template>
  <form style="width: 800px; max-width: 100%" @submit.prevent="save">
    <h3 class="card-title">Cargar empleado</h3>
    <div class="cols2">
      <suggest
        id="empleado"
        label="Empleado"
        placeholder="Buscar empleado.."
        :data="personas"
        :items="personaList"
        @selected="empleadoSelected"
        :required="enviado && !empleado"
      ></suggest>
      <pd-input
        v-model="parte.estado"
        id="estado"
        select
        label="Estado"
        placeholder="Seleccione estado"
        :required="(enviado && parte.estado === null)"
      >
        <template slot="options">
          <option value="">Seleccione</option>
          <option value="Presente">Presente</option>
          <option value="Ausente">Ausente</option>
        </template>
      </pd-input>
    </div>
    <template v-if="parte.estado === 'Presente'">
      <pd-input
        v-model="parte.notas"
        id="notas"
        label="Notas"
        placeholder="Notas"
      />
    </template>
    <template v-if="parte.estado === 'Ausente'">
      <suggest
        id="tipo_licencia"
        label="Licencia"
        placeholder="Buscar licencia.."
        class="my-1"
        :data="tiposLicencia"
        :items="tipoLicenciaList"
        @selected="tipoLicenciaSelected"
        :required="enviado && !parte.licencia_id"
      ></suggest>
      <div class="cols2">
        <pd-input
          v-model="parte.licencia_desde"
          id="desde"
          label="Fecha inicio licencia"
          placeholder="Fecha de inicio de licencia"
          type="date"
          :required="enviado && !parte.licencia_id"
        ></pd-input>
        <pd-input
          v-model="parte.licencia_hasta"
          id="hasta"
          label="Fecha finalización licencia"
          placeholder="Fecha de finalización de licencia"
          type="date"
          :required="enviado && !parte.licencia_id"
        ></pd-input>
      </div>
    </template>
    <button class="btn btn-primary mt-2" type="submit">Guardar</button>
  </form>
</template>

<script>
import { PersonasService } from "../../personas/services/PersonasServices";
import Suggest from "@/components/elements/Suggest";
import { TiposLicenciasServices } from "../../tipos_licencias/services/TiposLicenciasServices";
import ParteDiarioServices from "../services/ParteDiarioServices";
import Swal from "sweetalert2";

export default {
  props: ["personas", "tiposLicencia", "fecha"],
  components: {
    Suggest,
  },
  data() {
    return {
      enviado: false,
      parte: {
        fecha: null,
        empleado_id: null,
        area_id: null,
        gerencia_id: null,
        estado: null,
        notas: null,
        licencia_id: null,
        licencia_hasta: null,
        licencia_desde: null,
      },
      areasConGuardia: [
        0,
        2, //Acueducto RC
        6, //Acueducto Oeste
        7, //Oficina técnica
        9, //Servicios generales
      ],
      empleado: null,
      tipo_licencia: null,
      personaList: PersonasService.dataList(),
      tipoLicenciaList: TiposLicenciasServices.dataList,
    };
  },
  mounted() {
    this.parte.fecha = this.fecha;
  },
  methods: {
    save() {
      this.enviado = true;
      if (
        !this.parte.empleado_id ||
        this.parte.estado === null ||
        (this.parte.estado === "Ausente" &&
          (!this.parte.licencia_id ||
            !this.parte.licencia_hasta ||
            !this.parte.licencia_desde))
      ) {
        return;
      }
      ParteDiarioServices.api
        .addEmpleadoToParte(this.parte)
        .then((r) => {
          if (r.success == true) {
            this.$emit("saved", r.parte);
            Swal.fire("Éxito", `<p>Se guardó con éxito.</p>`, "success");
          } else {
            Swal.fire("Error", `<p>${r.message}</p>`, "error");
          }
        })
        .catch((e) => {
          Swal.fire("Error", `<p>${e}</p>`, "error");
        });
    },
    empleadoSelected(empleado) {
      if (empleado) {
        this.parte.empleado_id = empleado.id;
        this.parte.gerencia_id = empleado.gerencia_id;
        this.parte.area_id = empleado.area_id;
        this.empleado = empleado;
      }
    },
    tipoLicenciaSelected(tipoLicencia) {
      if (tipoLicencia) {
        this.parte.licencia_id = tipoLicencia.id;
        this.tipo_licencia = tipoLicencia;
      }
    },
  },
};
</script>