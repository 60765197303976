<template>
  <form style="width: 800px; max-width: 100%" @submit.prevent="save" v-if="parte">
    <h3 class="card-title">Editar parte diario</h3>
    <div class="cols2">
      <pd-input
        v-model="parte.empleado.nombre"
        readonly
        id="empleado"
        label="Empleado"
        placeholder="Empleado"
        type="text"
      ></pd-input>
      <pd-input
        v-model="parte.estado"
        id="estado"
        select
        label="Estado"
        placeholder="Seleccione estado"
      >
        <template slot="options">
          <option value="">Seleccione</option>
          <option value="Presente">Presente</option>
          <option value="Ausente">Ausente</option>
        </template>
      </pd-input>
    </div>
    <template v-if="parte.estado === 'Ausente'">
      <suggest
        id="tipo_licencia"
        label="Licencia"
        placeholder="Buscar licencia.."
        class="my-1"
        :data="tiposLicencia"
        :items="tipoLicenciaList"
        :value="parte.licencia"
        @selected="tipoLicenciaSelected"
        :required="enviado && !parte.licencia_id"
      ></suggest>
      <div class="cols2">
        <pd-input
          v-model="parte.licencia_desde"
          id="desde"
          label="Fecha inicio licencia"
          placeholder="Fecha de inicio de licencia"
          type="date"
          :required="enviado && !parte.licencia_id"
        ></pd-input>
        <pd-input
          v-model="parte.licencia_hasta"
          id="hasta"
          label="Fecha finalización licencia"
          placeholder="Fecha de finalización de licencia"
          type="date"
          :required="enviado && !parte.licencia_id"
        ></pd-input>
      </div>
    </template>
    <template v-if="parte.estado === 'Presente'">
      <pd-input
        v-model="parte.notas"
        id="notas"
        label="Notas"
        placeholder="Notas"
      />
    </template>
    <button class="btn btn-primary mt-2" type="submit">Guardar</button>
  </form>
</template>

<script>
import Suggest from "@/components/elements/Suggest";
import {TiposLicenciasServices} from "../../tipos_licencias/services/TiposLicenciasServices";
import ParteDiarioServices from "../services/ParteDiarioServices";
import Swal from "sweetalert2";

export default {
  props: ["parteSelected", "tiposLicencia"],
  components: {
    Suggest,
  },
  data() {
    return {
      parte: null,
      enviado: false,
      tipo_licencia: null,
      tipoLicenciaList: TiposLicenciasServices.dataList,
    };
  },
  watch: {
    'parte.estado'(newval, oldval) {
      if(newval === 'Presente' && oldval === 'Ausente'){
        this.parte.licencia = null
        this.parte.licencia_id = null
        this.parte.licencia_desde = null
        this.parte.licencia_hasta = null
      }else if(newval === 'Ausente' && oldval === 'Presente'){
        this.parte.nota = ''
      }
    }
  },
  mounted() {
    this.parte = Object.assign({}, this.parteSelected)
  },
  methods: {
    save() {
      this.enviado = true;
      if (
        !this.parte.empleado_id ||
        this.parte.estado === null ||
        (this.parte.estado === "Ausente" &&
          (!this.parte.licencia_id ||
            !this.parte.licencia_hasta ||
            !this.parte.licencia_desde))
      ) {
        return;
      }
      ParteDiarioServices.api
        .editEmpleadoToParte(this.parte)
        .then((r) => {
          if (r.success == true) {
            this.$emit("saved", r.parte);
            Swal.fire("Éxito", `<p>Se guardó con éxito.</p>`, "success");
          } else {
            Swal.fire("Error", `<p>${r.message}</p>`, "error");
          }
        })
        .catch((e) => {
          Swal.fire("Error", `<p>${e}</p>`, "error");
        });
    },
    tipoLicenciaSelected(tipoLicencia) {
      if (tipoLicencia) {
        this.parte.licencia_id = tipoLicencia.id;
        this.tipo_licencia = tipoLicencia;
      }
    },
  },
};
</script>
