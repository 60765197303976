import Axios from 'axios'
export const TiposLicenciasServices = {
    //API
    api: {
        async find(id) {
            return Axios.get('tipos-licencia/' + id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => { throw error })
        },
        async getAll() {
            return Axios.get('tipos-licencia')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async save(tiposLicencia) {
            return Axios.post('tipos-licencia', tiposLicencia)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async put(id, tiposLicencia) {
            return Axios.put('tipos-licencia/' + id, tiposLicencia)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async delete(tiposLicencia) {
            return Axios.delete('tipos-licencia/' + tiposLicencia.id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        }
    },
    dataList: [
        { name: 'descripcion', class: 'text-primary' },
        { name: 'convenio', class: 'text-secondary text-small' },
    ],
}